<template>
  <div class="Contact">
    <v-container fluid class="py-0">
      <v-row class="Contact__first-row">
        <v-col
          cols="12"
          sm="6"
          order="1"
          order-sm="0"
          class="Contact__column-height"
        >
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto d-none">
              <div class="header">
                <span
                  class="d-block montserrat-bold titles-big-x line-height-some-titles"
                  >{{
                    firstLetterUppercase(contactSingleton.contact_card.title)
                  }}</span
                >

                <a href="#" target="_blank" class="not-text-decoration">
                  <span class="d-block continuos-texts-small mt-3">{{
                    contactSingleton.contact_card.phone
                  }}</span>
                </a>
                <a
                  :href="'mailto:' + contactSingleton.contact_card.email"
                  target="_blank"
                  class="not-text-decoration"
                >
                  <span class="d-block continuos-texts-small mt-3">{{
                    contactSingleton.contact_card.email
                  }}</span>
                </a>
              </div>
            </v-col>
            <v-col cols="auto ">
              <v-row class="Contact__row-map" justify="center">
                <v-col cols="auto">
                  <v-img
                    :src="
                      'https://www.cymcms.actstudio.xyz/' +
                      contactSingleton.contact_map.path
                    "
                    class="Contact__row-map--img-map"
                  ></v-img>
                </v-col>
                <v-col cols="10">
                  <div class="Contact__row-map-content-text">
                    <div class="icon-plus">
                      <img src="@/assets/icons/60-plus-cym-AZUL.svg" />
                    </div>
                    <span
                      class="bree-serif titles-big-x line-height-some-titles"
                    >
                      {{ contactSingleton.contact_map_text }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0" order="0" order-sm="1">
          <template v-if="contactSingleton.contact_cover != null">
            <v-img
              :src="
                'https://www.cymcms.actstudio.xyz/' +
                contactSingleton.contact_cover.path
              "
              class="Contact__img"
            ></v-img>
          </template>
        </v-col>
      </v-row>

      <Form></Form>

      
    </v-container>
  </div>
</template>

<script>
import Form from "../components/Contact/Form";

export default {
  components: {
    Form,
  },
  data() {
    return {
      contactSingleton: {},
    };
  },
  beforeCreate() {
    //fetch para el singleton de contacto
    fetch(
      "https://www.cymcms.actstudio.xyz/api/singletons/get/contact?token=aa5f1f62f706c89e9dae0ea5e2400a"
    )
      .then((data) => data.json())
      .then((data) => (this.contactSingleton = data));
  },
  mounted() {
    //emite el valor para cambiar el valor del logo
    this.$emit("update", 0);
  },
  methods: {
    firstLetterUppercase: function (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
};
</script>